<template>
  <div>
    <v-icon v-if="this.user.role_name == 'AGENT-VN' || this.user.role_name == 'SUB-AGENT-VN'" color="green" small class=""
      @click="getUserChild5">{{ reportUrl ? "mdi-eye" : "mdi-eye-off" }}
    </v-icon>
    <v-icon v-if="this.user.role_name !== 'AGENT-VN' && this.user.role_name !== 'SUB-AGENT-VN'" color="green" small
      class="" @click="getUserChild1">{{ reportUrl ? "mdi-eye" : "mdi-eye-off" }}
    </v-icon>
    <statement-list1 v-if="user.role_name != 'AGENT-VN' && user.role_name != 'SUB-AGENT-VN'" :darkmode="darkmode"
      :dialog="dialogListUser" :users="userChild" :user="user" :data-request="data1" v-on:getData1="getUserChild1"
      @onCloseDialog="dialogListUser = !dialogListUser" :date="date" />
    <statement-list5 v-if="user.role_name == 'AGENT-VN' || user.role_name == 'SUB-AGENT-VN'" :darkmode="darkmode"
      :dialog="dialogListUser" :users="userChild5" :data-request="data5" v-on:getData5="getUserChild5" :date="date"
      :user="user" @onCloseDialog="dialogListUser = !dialogListUser" />
       <loader v-if="this.loader1 == true" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2"
      bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import StatementList1 from "../statementList/Dialog1";
import StatementList5 from "../statementList/Dialog5";
import moment from "moment";
import Vue from "vue";

export default {
  components: { StatementList1, StatementList5 },
  props: ["darkmode", "item", "params", "dataDelete", "userPermission", "user", "date"],
  data() {
    return {
      validPassword: true,
      dialogListUser: false,
      loading: false,
      userChild: [],
      userChild5: [],
      data1: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["amount_win"],
        sortBy: 'amount_win',
        page: 1,
        rowsPerPage: 10,
        fields: [],
        search: "",
        type:"viewer",
        startDate:  moment().format("YYYY-MM-DD")+"T00:00:00",
        endDate:  moment().format("YYYY-MM-DD")+"T23:59:59",

        statusTime:false
      },
      data5: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["_id._id"],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: [],
        search: "",
        channelType : 0,
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
      },
      loader1:false
    };
  },
  watch: {
    user: function () {
      // Emit this information to the parents component
    }
  },
  computed: {
    reportUrl: function () {
      if (this.user) {
        if (this.user.role_name == "DEVELOPER" || this.user.role_name == "MANAGER" || this.user.role_name == "SPECIAL-MANAGER") return "senior";
        else if (this.user.role_name == "COMPANY" || this.user.role_name == "SUB-COMPANY") return "master";
        else if (this.user.role_name == "SENIOR-VN" || this.user.role_name == "SUB-SENIOR-VN") return "agent";
        else if (this.user.role_name == "MASTER-VN" || this.user.role_name == "SUB-MASTER-VN") return "player";
        else if (this.user.role_name == "AGENT-VN" || this.user.role_name == "SUB-AGENT-VN") return "playerDetail";
        else return null;
      }
      else return "Loading..."
    }
  },
  methods: {
    getUserChild1(v_search = "") {
        this.loader1 = true;
      if (v_search != undefined) {
        if (v_search.type && v_search.type == "click") v_search = ""
        else if (v_search != "") this.data1.search = v_search
        else this.data1.search = ""
      }
      else this.data1.search = ""
      if (this.date) {
          this.data1.startDate = moment(this.date.startDate).format("YYYY-MM-DD")+"T00:00:00" ;
          this.data1.endDate = moment(this.date.endDate).format("YYYY-MM-DD") + "T23:59:59";
        this.data1.statusTime = this.date.statusTime;
      }
      this.data1.sortByFormat.length < 1
        ? (this.data1.sortBy = "_id._id")
        : (this.data1.sortBy = this.data1.sortByFormat[0]);
      this.data1.descendingFormat.length > 0
        ? (this.data1.descending = this.data1.descendingFormat[0])
        : (this.data1.descending = true);
      this.data1.fields = this.calFields();
      Vue.$cookies.set("currency-type", 4, 0);
      this.$request
        .post({
          url: 'statementCurrency/' + this.reportUrl + "/" + this.calID(this.item),
          data: this.data1
        })
        .then(res => {
          if (res.data.code) {
            this.dialogListUser = true;
            this.userChild = res.data.data;
            this.loader1 = false;
          }
        });
    },
    getUserChild5(search = "") {
      if (search) this.data5.search = search
      else this.data5.search = " "
      if (this.date) {
        this.data5.startDate = this.date.startDate
        this.data5.endDate = this.date.endDate
      }
      this.data5.fields = this.calFields();
      this.data5.sortByFormat.length < 1
        ? (this.data5.sortBy = "_id")
        : (this.data5.sortBy = this.data5.sortByFormat[0]);
      this.data5.descendingFormat.length > 0
        ? (this.data5.descending = this.data5.descendingFormat[0])
        : (this.data5.descending = true);
      Vue.$cookies.set("currency-type", 4, 0);
      this.$request
        .post({
          url: 'statementCurrency/' + this.reportUrl + '/' + this.calID(this.item),
          data: this.data5
        })
        .then(res => {
          if (res.data.code) {
            this.dialogListUser = true;
            this.userChild5 = res.data.data;
          }
        });
    },
    calID(item) {
      if (this.user.role_name == "DEVELOPER" || this.user.role_name == "MANAGER" || this.user.role_name == "SPECIAL-MANAGER") {
        return item._id.com_id
      }
      if (this.user.role_name == "COMPANY" || this.user.role_name == "SUB-COMPANY") {
        return item._id.admin_id
      }
      else if (this.user.role_name == "SENIOR-VN" || this.user.role_name == "SUB-SENIOR-VN") return item._id.master_id;
      else if (this.user.role_name == "MASTER-VN" || this.user.role_name == "SUB-MASTER-VN") return item._id.agent_id;
      else if (this.user.role_name == "AGENT-VN" || this.user.role_name == "SUB-AGENT-VN") return item._id.player_id;
      else return this.user.role_name
    },
    calFields() {
      if (this.user.role_name == "DEVELOPER" || this.user.role_name == "MANAGER" || this.user.role_name == "SPECIAL-MANAGER") {
        return ["_id.com_id", "_id.com", "_id.con_balance", "_id.admin_id", "_id.admin", "_id.admin_balance", "amount", "amount_win"];
      }
      else if (this.user.role_name == "COMPANY" || this.user.role_name == "SUB-COMPANY") {
        return ["_id.com_id", "_id.com", "_id.con_balance", "_id.admin_id", "_id.admin", "_id.admin_balance", "_id.master_id", "_id.master", "_id.master_balance", "amount", "amount_win"];
      }
      else if (this.user.role_name == "SENIOR-VN" || this.user.role_name == "SUB-SENIOR-VN") return ["_id.com_id", "_id.com", "_id.con_balance", "_id.admin_id", "_id.admin", "_id.admin_balance", "_id.master_id", "_id.master", "_id.master_balance", "_id.agent_id", "_id.agent", "_id.agent_balance", "amount", "amount_win"];
      else if (this.user.role_name == "MASTER-VN" || this.user.role_name == "SUB-MASTER-VN") return ["_id.com_id", "_id.com", "_id.con_balance", "_id.admin_id", "_id.admin", "_id.admin_balance", "_id.master_id", "_id.master", "_id.master_balance", "_id.agent_id", "_id.agent", "_id.agent_balance", "_id.player_id", "_id.player", "_id.player_balance", "amount", "amount_win"];
      else if (this.user.role_name == "AGENT-VN" || this.user.role_name == "SUB-AGENT-VN") return ["co_type_of_betting.name", "co_type_of_betting.desc", "co_type_of_betting.color", "date", "fight_no", "is_win", "amount", "amount_win", "cast"];
      else return this.user.role_name
    },
    ...mapActions("$_oCStatementSummary", ["fetchListStatement"])
  }
};
</script>
<style scoped>
.action-div {
  text-align: left;
  padding-left: 5px
}
</style>
